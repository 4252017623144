import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: ${({ theme }) => theme.spacing(3, 6)};
	.MuiTypography-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	}
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(3, 5)};
		justify-content: flex-start;
		text-align: left;
		.MuiTypography-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		}
	}
`

export { BottomAreaWrapper }
