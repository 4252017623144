import { styled } from '@mui/system'
import { default as BHMenus } from '../menus'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	display: flex;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(6, '75px', 0)};
	}
`

const Menus = styled(BHMenus)`
	${({ theme }) => theme.breakpoints.down('md')} {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: ${({ theme }) => theme.spacing(2.5, 3)};
	}
	.MuiPaper-root {
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-bottom: ${({ theme }) => theme.spacing(4)};
		}
		&:first-of-type {
			border-top: 0;
		}
		.MuiAccordionSummary-content {
			color: ${({ theme }) => theme.palette.footer.color};
		}
	}
	a {
		color: ${({ theme }) => theme.palette.common.white};
	}
	.waterfall {
		${({ theme }) => theme.breakpoints.up('lg')} {
			> .MuiButtonBase-root {
				display: none;
			}
		}
		&.MuiPaper-root .MuiAccordionSummary-root {
			margin-bottom: 0;
		}
		.level-1 {
			padding-bottom: 32px;
			> .MuiTypography-root {
				font-weight: ${({ theme }) => theme.typography.fontWeightBold};
				line-height: 1.15;
				${({ theme }) => theme.breakpoints.up('lg')} {
					font-size: ${({ theme }) => theme.typography.pxToRem(18)};
				}
			}
			> ul {
				padding-top: ${({ theme }) => theme.spacing(2.5)};
			}
		}
	}
`

export { Menus, MiddleTopWrapper }
