import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useIntl } from 'react-intl'
import { Newsletter, TopAreaWrapper } from './top-area.styled'
import { cx } from '@emotion/css'
import { shape } from 'prop-types'

const TopArea = ({ newsletterProps }) => {
	const { newsletter } = useConfig()
	const { formatMessage } = useIntl()

	return newsletter?.enabled ? (
		<TopAreaWrapper className={cx('TopArea-root')}>
			<Newsletter
				source="Footer"
				action="dialog"
				buttonProps={{
					color: 'primary',
				}}
				checkboxProps={{
					labelProps: {
						sx: { alignItems: 'flex-start', marginTop: 24 },
					},
				}}
				className="BHNewsletter"
				description={formatMessage({ id: 'form_newsletter_description_outlet' })}
				descriptionProps={{
					color: 'grey.main',
				}}
				inputProps={{
					label: 'Email',
				}}
				title={formatMessage({ id: 'form_newsletter_title_outlet' })}
				titleProps={{
					variant: 'h5',
				}}
				variant="standard"
				{...newsletterProps}
			/>
		</TopAreaWrapper>
	) : null
}

TopArea.propTypes = {
	/**
	 * Newsletter props
	 */
	newsletterProps: shape(Newsletter.propTypes),
}

export default TopArea
