import { cx } from '@emotion/css'
import { FormattedMessage } from 'react-intl'
// import MapPinIcon from '../../../atoms/icons/map-pin-icon'
import SocialLinks from '../social-links'
// import StorelocatorLink from '../storelocator-link'
import {
	ChangeCountryLanguageStyled,
	MiddleBottomWrapper,
	SocialLinksWrapper,
	SocialLinksTitle,
} from './middle-bottom-area.styled'

const MiddleBottomArea = (className) => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<ChangeCountryLanguageStyled className="change-country-language-trigger-footer" />
			<SocialLinksWrapper>
				<SocialLinksTitle>
					<FormattedMessage id={'follow_lesilla_social_title'} />
				</SocialLinksTitle>
				<SocialLinks
					buttonProps={{ component: 'a', variant: 'icon', color: 'primary' }}
					iconProps={{ fontSize: 'middle' }}
					className="BHFooterSocialLinks"
				/>
			</SocialLinksWrapper>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
