import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 46px;
	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: flex-end;
		padding: ${({ theme }) => theme.spacing(1.75, 9.3)};
	}
	.BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		text-align: left;
		justify-content: left;
		align-items: center;
		padding-bottom: 14px;
		margin-left: -16px;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			justify-content: flex-end;
		}
		.BHSocialWrapper {
			& a {
				margin: 0;
				padding: 16px;
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: flex-start;
		padding: 30px 0;
		.MuiTypography-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			justify-content: center;
			order: 2;
			flex-grow: 0;
			margin-left: 40px;
			width: auto;
			border-bottom: none;
			border-right: none;
			padding: 0;
			.MuiTypography-root {
				font-size: ${({ theme }) => theme.typography.pxToRem(14)};
			}
		}
	}
`

const SocialLinksWrapper = styled('div')`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.down('md')} {
		flex: 1 1 100%;
		padding-top: 24px;
	}
`

const SocialLinksTitle = styled('span')`
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	${({ theme }) => theme.breakpoints.up('md')} {
		padding-bottom: ${({ theme }) => theme.spacing(1)};
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper, SocialLinksWrapper, SocialLinksTitle }
